import { useState } from "react";
import React from "react";
import "./Home.scss";
import Slider from "react-slick";
import CardHome from "../../../Components/CardHome/CardHome";
import anchorswapLogo from "../../../assets/images/anchorswap-header-logo.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ContractServices } from "../../../services/ContractServices";
import { LotteryServices } from "../../../services/LotteryServices";
import { FarmService } from "../../../services/FarmService";
import { ExchangeService } from "../../../services/ExchangeService";
import { useSelector } from "react-redux";
import { toast } from "../../../Components/Toast/Toast";
import icon_coinAnchor from "../../../assets/images/icon_coinAnchor.png";
import icon_coinBnb from "../../../assets/images/icon_coinBnb.png";
import alt_img from "../../../assets/images/AnchorFinance-Partners.png";

import {
  MAIN_CONTRACT_LIST,
  BURN_ADDRESS,
  ANCHOR_BUSD_LP,
  WETH,
  TOKEN_LIST,
  BNB_BUSD_LP,
} from "../../../assets/tokens";
import WalletList from "../../../Components/Header/WalletList";
import Button from "../../../Components/Button/Button";
import {
  addTransaction,
  savereffralAddress,
  startLoading,
  stopLoading,
} from "../../../redux/actions";
import { ReferralsServices } from "../../../services/ReferralsServices";
import { BigNumber } from "bignumber.js";
import Loader from "react-loader-spinner";
import { addCommas, HOME_ROUTE } from "../../../constant";
import { useHistory, useParams } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import discord from "../../../assets/images/Discord-Channel.jpg";
import coinmarket from "../../../assets/images/CoinMarket.png";
import coingecko from "../../../assets/images/CoinGecko.png";
import coinmooner from "../../../assets/images/CoinMooner.png";
import coinvote from "../../../assets/images/CoinVote.png";
import dex from "../../../assets/images/DexScreener.png";
import defi from "../../../assets/images/DefiPulse.png";
import coinhunter from "../../../assets/images/CoinHunters.png";
import gem from "../../../assets/images/GEMFinder.png";
import discover from "../../../assets/images/CionDiscovery.png";
import youtube from "../../../assets/images/youtube.svg";
import telegram from "../../../assets/images/telegram.svg";
import twitter from "../../../assets/images/twitter.svg";
import NAFT from "../../../assets/images/NAFT-Logo.png";
import AnchorLink from "../../../assets/images/AnchorLink-Logo.png";
import TopFarms from "../../../Components/TopFarms";
import AllAnalytics from "../../../Components/AllAnalytics";
import Web3 from "web3";
import forward from "../../../assets/images/forward.png";

const Home = () => {
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const dispatch = useDispatch();
  const isUserConnected = useSelector((state) => state.persist.isUserConnected);
  const saveRefLink = useSelector((state) => state.persist.referralLink);
  const [potDetails, setPotDetails] = useState({
    prizeArray: [0, 0, 0],
    miniPrice: 0,
    pot: 0,
    decimals: 0,
  });
  const { ref } = useParams();
  const referralAddress = useSelector((state) => state.persist.referralAddress);
  const [rewards, seRewards] = useState(0);
  const [anchorPerBlock, setAnchorPerBlock] = useState(0);
  const [transferTaxRate, setTransferTaxRate] = useState(0);
  const [burnedToken, setBurnedToken] = useState(0);
  const [walletShow, setWalletShow] = useState(false);
  const [poolLength, setPoolLength] = useState(0);
  const [farms, setFarms] = useState([]);
  const [inactiveFarms, setInactiveFarms] = useState([]);
  const [stakeData, setStakeData] = useState(null);
  const [stakeValue, setStakeValue] = useState(0);
  const [referrer, setReferrer] = useState(
    "0x0000000000000000000000000000000000000000"
  );
  const [totalAmount, setAmount] = useState(0);
  const [totalRewards, setRewards] = useState(0);
  const [marketCap, setMarketCap] = useState(0.0);
  const [anchorTotalSupply, setAnchorTotalSupply] = useState(0);
  const [anchorBusdValue, setAnchorBusdValue] = useState(0);
  const [totalMinted, setTotalMinted] = useState(0);
  const [totalLockedRewards, setTotalLockedRewards] = useState(0);
  const [anchorBnbWorth, setAnchorBnbWorth] = useState(0);
  const [anchorBusdWorth, setAnchorBusdWorth] = useState(0);
  const [tokenIds, setTokenIds] = useState();
  const [allowance, setAllowance] = useState(false);
  const [disable, setDisabledBUtton] = useState(false);
  const [IButton, setIButton] = useState(false);
  const [ticketWindow, openTicketWindow] = useState(false);
  const [ticketValue, setvalue] = useState(1);
  const [buyButton, setBuyButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [currentTicketsArray, setCurrentArray] = useState([]);
  const [showHarvest, setShowHarvest] = useState(false);
  const [harvest, setHarvestAll] = useState([]);
  const [stakeConfirmation, setStakeConfimation] = useState(0);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [txHash, setTxHash] = useState("");
  const [farmAndStakeLoader, setFarmAndStakeLoader] = useState(false);
  const [liquidity, setLiquidity] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showStake, setShowStake] = useState(false);
  const [showStakeWithdraw, setShowStakeWithdraw] = useState(false);
  const [roiModalData, setRoiModalData] = useState(null);
  const [lpDetails, setLpTokenDetails] = useState(null);
  const [showAPY, setShowAPY] = useState(false);

  useEffect(() => {
    // init();
    // checkReferral();
  }, [isUserConnected]);

  // const checkReferral = async () => {
  //   if (ref || saveRefLink) {
  //     let re = await ReferralsServices.getReferrer(isUserConnected);
  //     if (re != "0x0000000000000000000000000000000000000000") {
  //       toast.error(`This user has already referral`);
  //       return;
  //     }
  //     dispatch(savereffralAddress(ref));

  //     // toast.success(`Referral Applied, try staking now !`);
  //   }
  // };

  const init = async () => {
    const res = await ContractServices.isMetamaskInstalled();

    if (isUserConnected && res) {
      totalPotSize();
      getAnchorPerBlock();
      getTransferTaxRate();
      getBurnedToken();
      getAnchorDollarValue();
      getMarketCap();
      handleLiquidity(TOKEN_LIST[1].address, WETH);
      handleLiquidityForAnchorBusd(
        TOKEN_LIST[1].address,
        TOKEN_LIST[2].address
      );
      getAllowance();

      try {
        dispatch(startLoading());
        let ref = await ReferralsServices.getReferrer(isUserConnected);
        if (ref === "0x0000000000000000000000000000000000000000") {
          if (
            referralAddress &&
            referralAddress !== "0x0000000000000000000000000000000000000000"
          ) {
            ref = referralAddress;
          }
          setReferrer(ref);
        }
        dispatch(stopLoading());
        const pL = Number(await FarmService.poolLength());
        setPoolLength(pL);
        // let farmsTemp = [];
        let totalRewards = 0;
        let totalLockedRewards = 0;
        let totalLiquidity = 0;
        let options = [];
        const res = await ContractServices.getTokenBalance(
          TOKEN_LIST[1].address,
          isUserConnected
        );
        setAmount(res);
        // setFarmAndStakeLoader(true);
        for (let i = 0; i < pL; i++) {
          const res = await FarmService.totalPoolInfo(i);
          const poolInfoForFarm = await FarmService.poolInfo(i, "1");
          const userInfo = await FarmService.userInfo(i, isUserConnected);
          const { poolInfo, latest } = res;

          if (i === 0 || i == 1 || (i == 8 && poolInfoForFarm)) {
            setFarms((farms) => [...farms, { poolInfo, userInfo, pid: i }]);
          }
          if (poolInfo.lpToken != undefined) {
            const allowance = await ContractServices.allowanceToken(
              poolInfo.lpToken,
              MAIN_CONTRACT_LIST.farm.address,
              isUserConnected
            );
            let check = true;
            if (
              BigNumber(allowance).isGreaterThanOrEqualTo(
                BigNumber(2 * 255 - 1)
              )
            ) {
              // setShowApproveButton(false);
              check = false;
            }
            const reserve = await ExchangeService.getReserves(ANCHOR_BUSD_LP);
            const tokenZero = await ExchangeService.getTokenZero(
              ANCHOR_BUSD_LP
            );
            const tokenOne = await ExchangeService.getTokenOne(ANCHOR_BUSD_LP);
            const anchorPerBlock = Number(await FarmService.pantherPerBlock());

            const price = await getPriceInUsd(tokenZero, tokenOne, reserve);

            totalLockedRewards +=
              (latest - poolInfo.lastRewardBlock) *
              price *
              (anchorPerBlock / 10 ** 18);
            setTotalLockedRewards(totalLockedRewards);
            const farmPoolInfo = await FarmService.farmAndPoolInfo(i);
            const { farm, pool } = farmPoolInfo;
            if (farm) {
              let res = await handleTotalLiquidity(farm.lpToken);
              totalLiquidity += Number(res);
            }
            if (pool) {
              const tokenAmount = await ExchangeService.getTokenStaked(
                pool.lpToken
              );
              let price = 0;
              if (
                pool.lpToken.toLowerCase() ===
                TOKEN_LIST[2].address.toLowerCase()
              ) {
                price = 1;
              } else {
                const tokenPairUSDT = await ExchangeService.getPair(
                  pool.lpToken,
                  TOKEN_LIST[2].address
                );
                price = await calPrice(tokenPairUSDT);
              }

              const liq = tokenAmount * price;
              totalLiquidity += Number(liq);
              setLiquidity(totalLiquidity);
            }
            const rewards = Number(
              Number(
                (await FarmService.pendingPanther(i, isUserConnected)) /
                10 ** 18
              ).toFixed(3)
            );
            totalRewards += rewards;
            setRewards(totalRewards);

            const nextHarvestUntil = await FarmService.canHarvest(
              i,
              isUserConnected
            );
            if (
              !check &&
              rewards > 0 &&
              Number(userInfo.nextHarvestUntil) > 0 &&
              nextHarvestUntil
            ) {
              setShowHarvest(true);
              options.push({ pid: i, lpToken: poolInfo.lpToken });
            }
          }
        }
        setHarvestAll(options);
      } catch (err) {
        console.log(err);
        setFarmAndStakeLoader(false);
      }
    }
  };

  const handleIndex = (index) => {
    if (currentIndex === index) {
      setCurrentIndex(-1);
    } else {
      setCurrentIndex(index);
    }
  };

  const stakeHandle = (data, type) => {
    if (type === "withdraw") {
      setStakeData(data);
      setShowStakeWithdraw(true);
    }
    if (type === "deposit") {
      setStakeData(data);
      setShowStake(true);
    }
  };

  const handleRoiModal = (data, lpDetails) => {
    setRoiModalData(data);
    setLpTokenDetails(lpDetails);
    setShowAPY(true);
  };
  const getPriceInUsd = async (tokenZero, tokenOne, reserve) => {
    let price;

    const decimalZero = await ContractServices.getDecimals(tokenZero);
    const decimalOne = await ContractServices.getDecimals(tokenOne);

    if (tokenZero.toLowerCase() === TOKEN_LIST[2].address.toLowerCase()) {
      price = (reserve[0] * decimalOne) / (reserve[1] * decimalZero);
    }

    if (tokenOne.toLowerCase() === TOKEN_LIST[2].address.toLowerCase()) {
      price = (reserve[1] * decimalZero) / (reserve[0] * decimalOne);
    }

    return price;
  };
  const totalPotSize = async () => {
    const address = await ContractServices.isMetamaskInstalled("");
    if (address) {
      try {
        const { amount, decimals, prizeArray, price } =
          await LotteryServices.getTotalPotSize();

        setPotDetails({
          ...potDetails,
          pot: amount,
          miniPrice: price,
          decimals,
          prizeArray,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const getAnchorPerBlock = async () => {
    try {
      const anchorPerBlock = Number(await FarmService.pantherPerBlock());
      setAnchorPerBlock(anchorPerBlock / 10 ** 18);
    } catch (error) {
      console.log(error);
    }
  };
  const getRewards = async (index) => {
    try {
      const response = await LotteryServices.getRewards(isUserConnected, index);
      seRewards(response.totalRewards);
      setTokenIds(response.tokenIds);
    } catch (error) {
      console.log(error);
    }
  };
  const getTransferTaxRate = async () => {
    try {
      const response = await LotteryServices.getTransferTaxRate();
      setTransferTaxRate(response);
    } catch (error) {
      console.log(error);
    }
  };
  const getBurnedToken = async () => {
    try {
      const response = await ExchangeService.getBurnedToken();
      setBurnedToken(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getMarketCap = async () => {
    const dollarValue = await getAnchorDollarValue();
    const totalSupply = await getTotalSupply();
    setMarketCap(dollarValue * totalSupply);
  };

  const getAnchorDollarValue = async () => {
    const reserves = await ExchangeService.getReserves(
      "0xC0Ff9f250d2D97F90BC89bD16D3B5344CdC68d06"
    );
    setAnchorBusdValue(reserves[1] / reserves[0]);
    return reserves[1] / reserves[0];
  };
  const getTotalSupply = async () => {
    const res = await ExchangeService.getTotalSupply(
      MAIN_CONTRACT_LIST.anchor.address
    );
    const anchorTotalSupply = res;
    const txAmount = (0.05 * anchorTotalSupply) / 100;
    setTotalMinted(anchorTotalSupply);
    setAnchorTotalSupply(txAmount);
    return res;
  };
  const getTicketCurrentIndex = async () => {
    setLoader(true);
    const response = await LotteryServices.getCurrentTickets(isUserConnected);
    setCurrentArray(response);
    setLoader(false);
  };
  const handleLiquidityForAnchorBusd = async (token0, token1) => {
    const pairAddress = await ExchangeService.getPair(token0, token1);
    const reserve = await ExchangeService.getReserves(pairAddress);
    const tokenZero = await ExchangeService.getTokenZero(pairAddress);
    const tokenOne = await ExchangeService.getTokenOne(pairAddress);

    const decimalZero = await ContractServices.getDecimals(tokenZero);
    const decimalOne = await ContractServices.getDecimals(tokenOne);
    const decimalPair = await ContractServices.getDecimals(pairAddress);

    const priceOfTokenZero = await getPriceByTokens(tokenZero);
    const priceOfTokenOne = await getPriceByTokens(tokenOne);
    const totalSupply = await ExchangeService.getTotalSupply(pairAddress);

    const liquidity =
      ((reserve[0] / 10 ** decimalZero) * priceOfTokenZero +
        (reserve[1] / 10 ** decimalOne) * priceOfTokenOne) /
      totalSupply;

    setAnchorBusdWorth(liquidity);
    return;
  };
  const anchorValue = useSelector((state) => state.persist.anchorValue);

  const handleLiquidity = async (token0, token1) => {
    const pairAddress = await ExchangeService.getPair(token0, token1);
    const reserve = await ExchangeService.getReserves(pairAddress);
    const tokenZero = await ExchangeService.getTokenZero(pairAddress);
    const tokenOne = await ExchangeService.getTokenOne(pairAddress);

    const decimalZero = await ContractServices.getDecimals(tokenZero);
    const decimalOne = await ContractServices.getDecimals(tokenOne);

    const priceOfTokenZero = await getPriceByTokens(tokenZero);
    const priceOfTokenOne = await getPriceByTokens(tokenOne);
    const totalSupply = await ExchangeService.getTotalSupply(pairAddress);

    const liquidity =
      ((reserve[0] / 10 ** decimalZero) * priceOfTokenZero +
        (reserve[1] / 10 ** decimalOne) * priceOfTokenOne) /
      totalSupply;

    setAnchorBnbWorth(liquidity);
    return;
  };
  const getPriceByTokens = async (token) => {
    const pairAddress = await ExchangeService.getPair(
      token,
      TOKEN_LIST[2].address
    );
    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      const tokenZero = await ExchangeService.getTokenZero(pairAddress);
      const tokenOne = await ExchangeService.getTokenOne(pairAddress);
      const reserve = await ExchangeService.getReserves(pairAddress);
      const decimalZero = await ContractServices.getDecimals(tokenZero);
      const decimalOne = await ContractServices.getDecimals(tokenOne);
      let price = 0;
      if (tokenZero.toLowerCase() === TOKEN_LIST[2].address.toLowerCase()) {
        price =
          (reserve[0] * 10 ** decimalOne) / (reserve[1] * 10 ** decimalZero);
      }
      if (tokenOne.toLowerCase() === TOKEN_LIST[2].address.toLowerCase()) {
        price =
          (reserve[1] * 10 ** decimalZero) / (reserve[0] * 10 ** decimalOne);
      }
      return price;
    }

    return 0;
  };
  const handleClaimRewards = async () => {
    await LotteryServices.getClaim(tokenIds, isUserConnected, rewards);
  };
  const getAllowance = async () => {
    try {
      const response = Number(
        await ContractServices.allowanceToken(
          MAIN_CONTRACT_LIST.anchor.address,
          MAIN_CONTRACT_LIST.lottary.address,
          isUserConnected
        )
      );

      if (response !== 0) {
        setAllowance(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggle1 = async () => {
    const value =
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
    setDisabledBUtton(true);
    const response = await ContractServices.approveToken(
      isUserConnected,
      value,
      MAIN_CONTRACT_LIST.lottary.address,
      MAIN_CONTRACT_LIST.anchor.address
    );

    if (response.status) {
      setIButton(true);
      setDisabledBUtton(false);
      setAllowance(true);
    } else {
      setDisabledBUtton(false);
    }
  };
  const onChange = (event) => {
    if (
      event.target.value == "" ||
      (!isNaN(event.target.value) && event.target.value.match("^[^.]+$"))
    ) {
      setvalue(event.target.value);
    }
  };
  const getMax = async () => {
    const contract = await ContractServices.callContract(
      MAIN_CONTRACT_LIST.anchor.address,
      MAIN_CONTRACT_LIST.anchor.abi
    );
    const balance = await contract.methods.balanceOf(isUserConnected).call();
    const value = Math.floor(balance / miniPrice);
    return value;
  };
  const setMax = async () => {
    setvalue(await getMax());
  };
  const generateRandomNumbers = async () => {
    try {
      const contract = await ContractServices.callContract(
        MAIN_CONTRACT_LIST.lottary.address,
        MAIN_CONTRACT_LIST.lottary.abi
      );
      const maxNumber = await contract.methods.maxNumber().call();
      let number;
      let array = [];
      for (let i = 1; i <= 4; i++) {
        number = Math.floor(Math.random() * maxNumber) + 1;
        array = [...array, number];
      }
      return array;
    } catch (error) {
      console.log(error);
    }
  };
  const buyTicket = async () => {
    try {
      if (ticketValue > 0) {
        setBuyButton(true);
        const web3 = await ContractServices.callWeb3();
        const gasPrice = await ContractServices.calculateGasPrice();
        const contract = await ContractServices.callContract(
          MAIN_CONTRACT_LIST.lottary.address,
          MAIN_CONTRACT_LIST.lottary.abi
        );
        let value = 0;
        value = await web3.utils.toHex(value);
        const arrayMajor = [];

        for (let number = 1; number <= ticketValue; number++) {
          arrayMajor.push(await generateRandomNumbers());
        }
        const gas = await contract.methods
          .multiBuy(`${miniPrice}`, arrayMajor)
          .estimateGas({ from: isUserConnected, value });
        const response = await contract.methods
          .multiBuy(`${miniPrice}`, arrayMajor)
          .send({ from: isUserConnected, gasPrice, gas, value });
        if (response.status) {
          const amount = await contract.methods.totalAmount().call();
          setPotDetails({
            ...potDetails,
            pot: amount,
          });
          setBuyButton(false);
          setvalue(1);
          openTicketWindow(!ticketWindow);
        }
        getTicketCurrentIndex();
      } else {
        setvalue(1);
        return toast.error("Ticket should be greater then zero");
      }
    } catch (error) {
      setBuyButton(false);
      console.log(error);
    }
  };
  const harvestAll = async () => {
    const acc = await ContractServices.getDefaultAccount();
    if (acc && acc.toLowerCase() !== isUserConnected.toLowerCase()) {
      return toast.error("Wallet address doesn`t match!");
    }

    if (stakeConfirmation) {
      return toast.info("Transaction is processing!");
    }
    try {
      harvest &&
        harvest.map(async (item) => {
          setStakeConfimation(true);
          const data = {
            pid: item.pid,
            amount: 0,
            referrer: referrer,
            from: isUserConnected,
          };

          dispatch(startLoading());
          const result = await FarmService.deposit(data);
          dispatch(stopLoading());
          setStakeConfimation(false);

          if (result) {
            setTxHash(result);
            setShowTransactionModal(true);

            const data = {
              message: `Harvest ${item.lpTokenName}`,
              tx: result,
            };
            dispatch(addTransaction(data));
          }
        });
      dispatch(stopLoading());
    } catch (err) {
      console.log(err, "lp harvest");
      dispatch(stopLoading());
      setStakeConfimation(false);

      const message = await ContractServices.web3ErrorHandle(err);
      toast.error(message);
    }
  };
  const calPrice = async (pairAddress) => {
    let price = 0;

    if (pairAddress == "0x0000000000000000000000000000000000000000") {
      return 0;
    }

    // console.log("pairAddresspairAddress", pairAddress);
    const tokenZero = await ExchangeService.getTokenZero(pairAddress);
    const tokenOne = await ExchangeService.getTokenOne(pairAddress);
    const reserve = await ExchangeService.getReserves(pairAddress);

    const decimalZero = await ContractServices.getDecimals(tokenZero);
    const decimalOne = await ContractServices.getDecimals(tokenOne);

    // console.log(tokenZero, TOKEN_LIST[2].address);

    if (tokenZero.toLowerCase() === TOKEN_LIST[2].address.toLowerCase()) {
      return (price =
        (reserve[0] * 10 ** decimalOne) / (reserve[1] * 10 ** decimalZero));
    }

    if (tokenOne.toLowerCase() === TOKEN_LIST[2].address.toLowerCase()) {
      return (price =
        (reserve[1] * 10 ** decimalZero) / (reserve[0] * 10 ** decimalOne));
    }

    let priceBNBToUSD = calPrice(BNB_BUSD_LP); //replace with BNB-USD pair

    if (tokenZero.toLowerCase() === WETH.toLowerCase()) {
      price =
        (reserve[0] * 10 ** decimalOne) / (reserve[1] * 10 ** decimalZero);
      return price * priceBNBToUSD;
    }

    if (tokenOne.toLowerCase() === WETH.toLowerCase()) {
      price =
        (reserve[1] * 10 ** decimalZero) / (reserve[0] * 10 ** decimalOne);
      return price * priceBNBToUSD;
    }
  };
  const handleTotalLiquidity = async (pairAddress) => {
    if (pairAddress != "0x0000000000000000000000000000000000000000") {
      const tokenZero = await ExchangeService.getTokenZero(pairAddress);
      const tokenOne = await ExchangeService.getTokenOne(pairAddress);
      const reserve = await ExchangeService.getReserves(pairAddress);

      const tokenZeroPairUSDT = await ExchangeService.getPair(
        tokenZero,
        TOKEN_LIST[2].address
      );
      const tokenOnePairUSDT = await ExchangeService.getPair(
        tokenOne,
        TOKEN_LIST[2].address
      );

      const tokenZeroPairBNB = await ExchangeService.getPair(tokenZero, WETH);
      const tokenOnePairBNB = await ExchangeService.getPair(tokenOne, WETH);

      const decimalZero = await ContractServices.getDecimals(tokenZero);
      const decimalOne = await ContractServices.getDecimals(tokenOne);
      // const decimalPair = await ContractServices.getDecimals(pairAddress);

      let priceA = 0;
      let priceB = 0;

      if (tokenZero.toLowerCase() == TOKEN_LIST[2].address.toLowerCase()) {
        priceA = 1;
      } else if (tokenZero.toLowerCase() == WETH.toLowerCase()) {
        priceA = await calPrice(BNB_BUSD_LP);
      }

      if (tokenOne.toLowerCase() == TOKEN_LIST[2].address.toLowerCase()) {
        priceB = 1;
      } else if (tokenOne.toLowerCase() == WETH.toLowerCase()) {
        priceB = await calPrice(BNB_BUSD_LP);
      }

      if (priceA == 0) {
        if (tokenZeroPairUSDT != "0x0000000000000000000000000000000000000000") {
          priceA = await calPrice(tokenZeroPairUSDT);
        } else if (
          tokenZeroPairBNB != "0x0000000000000000000000000000000000000000"
        ) {
          priceA = await calPrice(tokenZeroPairBNB);
        } else {
          priceA = 0;
        }
      }

      if (priceB == 0) {
        if (tokenOnePairUSDT != "0x0000000000000000000000000000000000000000") {
          priceB = await calPrice(tokenOnePairUSDT);
        } else if (
          tokenOnePairBNB != "0x0000000000000000000000000000000000000000"
        ) {
          priceB = await calPrice(tokenOnePairBNB);
        } else {
          priceB = 0;
        }
      }

      const totalSupply = await ExchangeService.getTotalSupply(pairAddress);
      const tokenStaked = await ExchangeService.getTokenStaked(pairAddress);

      const liquidity =
        (((reserve[0] / 10 ** decimalZero) * priceA +
          (reserve[1] / 10 ** decimalOne) * priceB) /
          totalSupply) *
        tokenStaked;

      return liquidity;
    }
    return 0;
  };
  const { prizeArray, miniPrice, pot, decimals } = potDetails;
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 0,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 0,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  var setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        setting: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        setting: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 1008,
        setting: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        setting: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  const history = useHistory();
  // const redirect = () => { history. push('/auth/analytics'); }
  function handleClickForAnalytics() {
    history.push("/auth/analytics");
  }
  function handleClickForFarm() {
    history.push("/auth/farm");
  }

  function handleRef() {
    history.push("/auth/referralPage");
  }

  // console.log("==================", farms);
  return (
    <div className="container_wrap">
      <div className="container-fluid anchor">
        <div className="row subheader_row">
          <div className="col subHeader_style">
            <div className="left_anchor_sec">
              <img src={anchorswapLogo} className="anchor_logo" />
              {/* <p>The First Automatic Multichain Liquidity Acquisition Yield Farm & AMM.</p> */}
              <ul className="head">
                <li>Buy Anchor Tokens & join this hidden GEM </li>
                <li> Start earning on high performing Yield Farms</li>
                <li>Earn Referral Rewards by inviting your friends </li>
                <li>Benefit of the whole AnchorFinance ecosystem</li>
              </ul>
              <Button>Start earning now</Button>
            </div>
          </div>
          <div className="col subHeader_style">
            <Slider {...setting} className="slidr_style">
              <div>
                <img src={discord} alt="icon" />
              </div>
              <div>
                <img src={discord} alt="icon" />
              </div>
              <div>
                <img src={discord} alt="icon" />
              </div>
              <div>
                <img src={discord} alt="icon" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="container container_inside">
        <div className="row subheader_row_new">
          <div className="col subHeader_new_style">
            <div className="value_sec_1">
              <div className="anchor_price_sec">
                <span>
                  <h2>ANCHOR Token Price</h2>
                </span>
                <span>
                  <h2>${anchorValue}</h2>
                </span>
              </div>
              <div className="link_sec">
                <span>
                  <a href="analytics">Check on Chart</a>
                  <div className="vl" />
                  <a href="analytics">View Analytics</a>
                </span>
              </div>
            </div>
          </div>
          <div className="col subHeader_new_style">
            <div className="value_sec_2">
              <div className="anchor_price_sec">
                <span>
                  <h2>Total Value Locked (TVL)</h2>
                </span>
                <span>
                  <h2>{addCommas(liquidity.toFixed(2))}</h2>
                </span>
              </div>
              <div className="link_sec">
                <span>
                  <a href="">Across all Farms & Pools</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row stats_row_sec">
          <div className="col substats_sec">
            <CardHome
              className="col_farm_stacking fullHeight no_img"
              topTitle="Farms & Staking"
              title="Your Earnings"
            >
              {/* <div className="row anchor_swapbtn_metamask_btn lessMargin_bottom">
                <Link to="/" className="addMetamask_btn">
                  <img src={addMetamask} />
                </Link>
              </div> */}
              {/* <p>Farms & Staking</p> */}
              <div className="col">
                {/* <div className="row lessMargin_bottom"> */}
                <ul className="anchor anchor_harvest_list">
                  <li className="anchor_harvest_list">
                    <span className="anchor_harvest_list__title">
                      ANCHOR to Harvest
                    </span>
                    <span>
                      <span className="anchor_harvest_list__lockedTextStyle">
                        {farmAndStakeLoader ? (
                          <Loader
                            type="Circles"
                            color="#FFFFFF"
                            height={15}
                            width={15}
                            visible={true}
                          // timeout={5000} //3 secs
                          />
                        ) : (
                          totalRewards.toFixed(3)
                        )}
                      </span>
                      <span className="anchor_harvest_list__valueTextStyle">
                        ~${(totalRewards * anchorBusdValue).toFixed(3)}
                      </span>
                    </span>
                  </li>
                  {/* </div> */}
                  {/* <div className="row"> */}
                  <li className="anchor_harvest_list">
                    <span className="anchor_harvest_list__title">
                      ANCHOR in Wallet
                    </span>
                    <span className="anchor_value_sec">
                      <span className="anchor_harvest_list__lockedTextStyle">
                        {addCommas(totalAmount)}
                      </span>
                      <span className="anchor_harvest_list__valueTextStyle">
                        ~${(totalAmount * anchorBusdValue).toFixed(3)}
                      </span>
                    </span>
                  </li>
                </ul>
                {/* </div> */}
              </div>
              <div className="row">
                {!isUserConnected && (
                  <div className="col unloack_btn_style">
                    <Button
                      className="full"
                      onClick={() => setWalletShow(true)}
                    >
                      {"Harvest All"}
                    </Button>
                  </div>
                )}
                {isUserConnected && (
                  <div className="col unloack_btn_style">
                    <Button
                      onClick={() => {
                        setShowHarvest(false);
                        harvestAll();
                      }}
                      className="full"
                      disabled={harvest.length > 0 ? false : true}
                    >
                      {harvest.length > 0
                        ? `Harvest All(${harvest.length})`
                        : "Harvest All"}
                    </Button>
                  </div>
                )}
              </div>
            </CardHome>
          </div>
          <div className="col substats_sec">
            <CardHome
              className="col_lottery_winning_style no_img"
              classTitle="lottery_title__style"
              topTitle="Decentralized Referral Link"
              title="Invite your Friends"
            // title={
            //   <>
            //     <span>dsgfvsdgbsd</span>
            //     Invite your Friends
            //   </>
            // }
            >
              {/* <div className="col">
                <div className="row lessMargin_bottom">
                  <ul className="lottery_winnig_list">
                    <li className="lottery_winnig__title">ANCHOR to Collect</li>
                    <li className="lottery_winnig__value">
                      {rewards ? Math.floor(rewards) : rewards} ANCHOR
                    </li>
                    <li className="lottery_winnig__valuesmall">
                      ~$
                      {(
                        (rewards ? Math.floor(rewards) : rewards) *
                        anchorBusdValue
                      ).toFixed(3)}
                    </li>
                  </ul>
                </div> */}
              {/* <div className="row">
                  <ul className="lottery_winnig_list">
                    <li className="lottery_winnig__title">
                      Total Jackpot This Round
                    </li>
                    <li className="lottery_winnig__value">
                      {pot / 10 ** decimals}
                    </li>
                    <li className="lottery_winnig__valuesmall">
                      ~${((pot / 10 ** decimals) * anchorBusdValue).toFixed(3)}
                    </li>
                  </ul>
                </div> */}
              {/* </div> */}
              <p>
                Benefit from our official decentralized Referral System and
                enjoy Referral Rewards from:<a href=""> Farms & Pool Swaps</a>
              </p>
              <div className="row">
                {!isUserConnected && (
                  <div className="col unloack_btn_style">
                    <Button className="full centered" onClick={handleRef()}>
                      {"Get your Referral Link"}
                    </Button>
                  </div>
                )}
                {/* {isUserConnected && rewards != 0 && (
                  <div className="col unloack_btn_style">
                    <Button
                      onClick={() => handleClaimRewards()}
                      className="full"
                    >
                      {"Collect Winnings"}
                    </Button>
                  </div>
                )} */}
                {isUserConnected && !rewards && (
                  <div className="col unloack_btn_style">
                    <Button className="full" disabled>
                      {"Get Your Referral Link"}
                    </Button>
                  </div>
                )}
                {isUserConnected && allowance && (
                  <div className="col unloack_btn_style">
                    <Button
                      className="full"
                      onClick={() => openTicketWindow(!ticketWindow)}
                    >
                      {"Buy Tickets"}
                    </Button>
                  </div>
                )}
                {/* {isUserConnected && !allowance && (
                  <div className="col unloack_btn_style">
                    <Button
                      className="full"
                      disabled={disable}
                      onClick={() => handleToggle1()}
                    >
                      {"Approve Anchor"}
                    </Button>
                  </div>
                )} */}
              </div>
            </CardHome>
          </div>
          <div className="col substats_sec">
            <CardHome
              className="col_lottery_winning_style no_img"
              classTitle="lottery_title__style"
              topTitle="Most important Infos"
              title="ANCHOR Stats"
            >
              <div className="col">
                {/* <div className="row lessMargin_bottom"> */}
                <ul
                  className={`lottery_winning_list ${isActive ? "modification" : ""
                    } `}
                >
                  <li>
                    <span>
                      <h4>Price</h4>
                      <p>${anchorValue}</p>
                    </span>
                  </li>
                  <li>
                    <span>
                      <h4>Market Cap </h4>
                      <p>${addCommas(marketCap.toFixed(2))}</p>
                    </span>
                  </li>
                  <li>
                    <span>
                      <h4>Total Minted </h4>
                      <p>{addCommas(totalMinted.toFixed(0))}</p>
                    </span>
                  </li>
                  <li>
                    <span>
                      <h4>Total Burned</h4>
                      <p>{addCommas(burnedToken.toFixed(2))}</p>
                    </span>
                  </li>
                  <li>
                    <span>
                      <h4> Circulating Supply</h4>
                      <p>181,437,863.93</p>
                    </span>
                  </li>
                  {/* <li className="lottery_winnig__title">Price</li>
                    <li className="lottery_winnig__value">
                      {rewards ? Math.floor(rewards) : rewards} ANCHOR
                    </li>
                    <li className="lottery_winnig__valuesmall">
                      ~$
                      {(
                        (rewards ? Math.floor(rewards) : rewards) *
                        anchorBusdValue
                      ).toFixed(3)}
                    </li> */}
                </ul>
                {/* </div> */}

                {/* <ul className="lottery_winnig_list">
                    <li className="lottery_winnig__title">
                      Total Jackpot This Round
                    </li>
                    <li className="lottery_winnig__value">
                      {pot / 10 ** decimals}
                    </li>
                    <li className="lottery_winnig__valuesmall">
                      ~${((pot / 10 ** decimals) * anchorBusdValue).toFixed(3)}
                    </li>
                  </ul> */}
              </div>
              <div className="row">
                {!isUserConnected && (
                  <div className="col unloack_btn_style">
                    <Button
                      className="full"
                      onClick={() => setWalletShow(true)}
                    >
                      {"Get you referral link"}
                    </Button>
                  </div>
                )}
                {isUserConnected && rewards != 0 && (
                  <div className="col unloack_btn_style">
                    <Button
                      onClick={() => handleClaimRewards()}
                      className="full"
                    >
                      {"Collect Winnings"}
                    </Button>
                  </div>
                )}
                {isUserConnected && allowance && (
                  <div className="col unloack_btn_style">
                    <Button
                      className="full"
                      onClick={() => openTicketWindow(!ticketWindow)}
                    >
                      {"Buy Tickets"}
                    </Button>
                  </div>
                )}
              </div>
            </CardHome>
          </div>
        </div>
        <div className="row token_row">
          <AllAnalytics />

        </div>

        <div className="anchor_wrap">
          <div className="title_sec">
            <span>
              <p>What’s coming next to the AnchorFinance Ecosystem</p>
              <Button>Visit Website</Button>
            </span>
            <h2>News & Announcements</h2>
          </div>
          <div class="row announce_row">
            <div className="col">
              <CardHome
                className="announcements_cardStyle n&a"
                title="NAFT NFT Platform"
                img={NAFT}
                subtitle="The Future Of NFT Is Anchor"
                content="NAFT is Anchors NFT platform on the Fantom’s Opera Network, where you don’t have any commissions on sales. NAFT charges you only 3 FTM for minting NFTs on the Opera Network. You can create, buy and sell as well as start an auction for your NFT paying only the usual transactions fees."
              >
                <Button className="full">Read More</Button>
              </CardHome>
            </div>
            <div className="col">
              <CardHome
                className="mb-26 n&a no_img"
                title="Prediction Trading"
                subtitle="based on BNB"
                content="A further milestone that the team is aiming to complete in Q2 of 2022 would be AnchorSwap’s custom multi-wallet, called AnchorLink. AnchorLink would provide the functionalities needed for going cross chain with the NFTs, which would lead to market expansion and a greater value for ANCHOR."
              >
                {/* <div className="row">
                  <div className="col">
                    <ul className="list_ValueStyle">
                      <li>
                        <span className="label">Market Cap</span>
                        <strong className="value">
                          ${addCommas(marketCap.toFixed(2))}
                        </strong>
                      </li>
                      <li>
                        <span className="label">Total Minted</span>
                        <strong className="value">
                          {addCommas(totalMinted.toFixed(2))}
                        </strong>
                      </li>
                      <li>
                        <span className="label">Total Burned</span>
                        <strong className="value">
                          {addCommas(burnedToken)}
                        </strong>
                      </li>
                      <li>
                        <span className="label">Total Locked Rewards</span>
                        <strong className="value">
                          {farmAndStakeLoader ? (
                            <Loader
                              type="Circles"
                              color="#FFFFFF"
                              height={15}
                              width={15}
                              visible={true}
                              // timeout={5000} //3 secs
                            />
                          ) : (
                            addCommas(totalLockedRewards.toFixed(2))
                          )}
                        </strong>
                      </li>
                      <li>
                        <span className="label">Circulating Supply</span>
                        <strong className="value">
                          {addCommas((totalMinted - burnedToken).toFixed(2))}
                        </strong>
                      </li>
                      <li>
                        <span className="label">Max Token Supply</span>
                        <strong className="value">250,000,000</strong>
                      </li>
                      <li>
                        <span className="label">Max Tx Amount</span>
                        <strong className="value">
                          {addCommas(anchorTotalSupply.toFixed(2))}
                        </strong>
                      </li>
                      <li>
                        <span className="label">New ANCHOR/Block</span>
                        <strong className="value">
                          {addCommas(anchorPerBlock)}
                        </strong>
                      </li>
                      <li>
                        <span className="label">Transfer Tax</span>
                        <strong className="value">{"2.5%"}</strong>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <Button className="full">Read More</Button>
              </CardHome>
            </div>
            {/* <CardHome
                            className="mb-26"
                            title="ANCHOR LP Worth"
                            classTitle="small"
                        >
                            <div className="row">
                                <div className="col">
                                    <ul className="list_ValueStyle">
                                        <li>
                                            <span className="label">ANCHOR-BNB</span>
                                            <strong className="value">${anchorBnbWorth.toFixed(2)}</strong>
                                        </li>
                                        <li>
                                            <span className="label">ANCHOR-BUSD</span>
                                            <strong className="value">${anchorBusdWorth.toFixed(2)}</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </CardHome> */}
            <div className="col">
              <CardHome
                className="totalValueLocked_style n&a"
                title="AnchorLink"
                img={AnchorLink}
                subtitle="Multichain Wallet With Fiat Gateway"
                content="A further milestone that the team is aiming to complete in Q2 of 2022 would be AnchorSwap’s custom multi-wallet, called AnchorLink. AnchorLink would provide the functionalities needed for going cross chain with the NFTs, which would lead to market expansion and a greater value for ANCHOR."
              >
                {/* <p>
                  {" "}
                  {farmAndStakeLoader ? (
                    <Loader
                      type="Circles"
                      color="#FFFFFF"
                      height={15}
                      width={15}
                      visible={true}
                      // timeout={5000} //3 secs
                    />
                  ) : (
                    addCommas(liquidity.toFixed(2))
                  )}
                </p>
                <strong>Across all Farms and Pools</strong> */}
                <Button className="full">More infos follow soon</Button>
              </CardHome>
            </div>
          </div>
        </div>

        <div className="anchor_wrap">
          <div className="title_sec">
            <p>Benefit from our best performers:</p>
            <span>
              <h2>Top Farms</h2>
              <Button onClick={() => window.open(`${HOME_ROUTE}auth/farm`, "")}>
                See all farms
              </Button>
            </span>
          </div>
          <div className="row farm_row">
            {farms.map((farm, index) => (
              <TopFarms
                key={index}
                index={index}
                farm={farm}
                coinLeft={icon_coinAnchor}
                coinRight={icon_coinBnb}
                currentIndex={currentIndex}
              />
            ))}

            {/* <div className="col farm_info">
              <div>
                
                <span>
                  <img src={POLKADOT} alt="" className="farm_img_one" />
                  <img src={WBNB} alt="" className="farm_img_two" />
                </span>

                <span className="sec">
                  <h2>DOT-WBNB</h2>
                  <p>Liquidity $1,377,691.54</p>
                </span>
              </div>
              <div className="farm_detail">
                <h2>
                  APR <span>197.86%</span>
                </h2>
                <p>
                  <span className="arr">
                    Start farming
                    <img src={rightarrow} alt="" />
                  </span>
                </p>
              </div>
            </div>

            <div className="col farm_info">
              <div>
                <span>
                  <img src={ANCHOR} alt="" className="farm_img_one" />
                  <img src={WBNB} alt="" className="farm_img_two" />
                </span>

                <span className="sec">
                  <h2>ANCHOR-WBNB</h2>
                  <p>Liquidity $1,377,691.54</p>
                </span>
              </div>
              <div className="farm_detail">
                <h2>
                  APR <span>197.86%</span>
                </h2>
                <p>
                  <span className="arr">
                    Start farming
                    <img src={rightarrow} alt="" />
                  </span>
                </p>
              </div>
            </div> */}
          </div>
        </div>

        <div className="partner_Wrap">
          <div className="title_sec">
            <p>You can only be the best if you work with the best.</p>
            <h2>Our Partners</h2>
          </div>{" "}
          <div class="row">
            <div className="anchor_partners">
              <span className="anc_partner_img">
                <img src={alt_img} alt="partner_img" className="img-fluid" />
              </span>
              <div className="partner_img">
                <img src={coinmarket} alt="" />
              </div>
              <div className="partner_img">
                <img src={coingecko} alt="" />
              </div>
              <div className="partner_img">
                <img src={coinmooner} alt="" />
              </div>
              <div className="partner_img">
                <img src={coinvote} alt="" />
              </div>

              <div className="partner_img">
                <img src={dex} alt="" />
              </div>
              <div className="partner_img">
                <img src={defi} alt="" />
              </div>
              <div className="partner_img">
                <img src={coinhunter} alt="" />
              </div>
              <div className="partner_img">
                <img src={gem} alt="" />
              </div>
              <div className="partner_img">
                <img src={discover} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="media_Wrap">
        <div class="row">
          <div className="social_sec">
            <span className="social_head">
              <h2>AnchorFinance</h2>
              <p>Visit our website for more information</p>
            </span>
            <span className="social_content">
              <a href="">
                <img src={forward} alt="" />
              </a>
            </span>
          </div>
          <div className="social_sec">
            <span className="social_head">
              <h2>Youtube</h2>
              <p>Trailer, Amas, project ipdates and more</p>
            </span>
            <span className="social_content">
              <a href="">
                <img src={youtube} alt="" />
              </a>
            </span>
          </div>
          <div className="social_sec">
            <span className="social_head">
              <h2>Telegram</h2>
              <p>Any Question? We care about you.</p>
            </span>
            <span className="social_content">
              <a href="">
                <img src={telegram} alt="" />
              </a>
            </span>
          </div>
          <div className="social_sec">
            <span className="social_head">
              <h2>Twitter</h2>
              <p>Follow Anchor and never miss on anything</p>
            </span>
            <span className="social_content">
              <a href="">
                <img src={twitter} alt="" />
              </a>
            </span>
          </div>
        </div>
      </div>

      {walletShow && <WalletList isWalletShow={() => setWalletShow()} />}
      {ticketWindow && (
        <span className="show">
          <div className="ticketpopup show">
            <div className="ticketpop">
              <h3>Enter amount of tickets to buy</h3>
              <Button
                className="close"
                onClick={() => openTicketWindow(!ticketWindow)}
              ></Button>
              <div className="popup_body">
                <div className="amountBox">
                  <input
                    onChange={(e) => onChange(e)}
                    value={ticketValue}
                    type="text"
                    className="form-control"
                  />
                  <h4>
                    Ticket
                    <Button className="btn-max" onClick={() => setMax()}>
                      Max
                    </Button>
                  </h4>
                </div>
                <p>1 Ticket = 20 ANCHOR</p>
                <p className="white">
                  Ticket purchases are final. Your ANCHOR cannot be returned to
                  you after buying tickets.
                </p>
                <p className="center spnd-txt">
                  You will spend: {""}
                  {ticketValue * (miniPrice / 10 ** decimals)}
                  ANCHOR
                </p>
                <ul>
                  <li>
                    <Button
                      onClick={() => {
                        openTicketWindow(!ticketWindow);
                      }}
                      className="noticket show"
                    >
                      Cancel
                    </Button>
                  </li>
                  <li>
                    <Button disabled={buyButton} onClick={() => buyTicket()}>
                      {buyButton ? "Pending Confirmation" : "Confirm"}
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </span>
      )}
    </div>
  );
};

export default Home;
